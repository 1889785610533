@import '~antd/dist/antd.css';

.ant-table-thead > tr > th.align-right {
  text-align: right;
}

.ant-table-tbody > tr > td.printer-cell {
  padding: 0;
  text-align: center;
  cursor: pointer;
}

.ant-table-tbody > tr > td.printer-cell i {
  font-size: 24px;
}

.ant-table-tbody > tr > td.printer-cell:hover {
  background: #aecbd8;
}
